<template>
<div>
<b-input-group prepend="Username" class="mt-3">
    <b-form-input/>
    <b-input-group-append>
      <b-button variant="outline-danger">Forgot?</b-button>
    </b-input-group-append>
  </b-input-group>
  <b-input-group prepend="Password" id="type-password"  class="mt-3">
    <b-form-input/>
    <b-input-group-append>
      <b-button variant="outline-light"> <b-form-checkbox/></b-button>
    </b-input-group-append>
  </b-input-group>
<b-input-group right>
</b-input-group>

<b-input-group>
    <b-input-group-button>
        <b-button-group>
             <b-button variant="success">Login</b-button>
        </b-button-group>
        <b-button-group>
            <b-button variant="primary">Facebook</b-button>
            <b-button variant="secondary">Github</b-button>
            <b-button variant="success">Google</b-button>
            <b-button variant="danger">Anonymous</b-button>
            <b-button variant="warning">Microsoft</b-button>
            <b-button variant="info">Twitter</b-button>
            <b-button variant="light">Phone Number</b-button>
            <b-button variant="dark">Apple</b-button>
        </b-button-group>
    </b-input-group-button>
</b-input-group>

</div>
</template>

<script>
export default {
name: "login"
}
</script>

<style scoped>

</style>